import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import back from '../../utils/images/back.svg';
import FileInput from "../FileInput/FileInput";
import { useNavigate } from "react-router-dom";
import cpo from '../../utils/images/cpo.svg';
import guide from '../../utils/Установка приложений из ЦПО.pdf';
import InputMask from "react-input-mask";
export default function CPOTypeInStep({
    selectedService,
    selectedComponent,
    handlePrevStep,
    setDescription,
    handleSubmit,
    isLoading,
    description
}) {
    const [isEmpty, setIsEmpty] = useState(false);
    const [filesArray, setFileBase64List] = useState([]);
    const [isChecked, setIsChecked] = useState(false)
    const [softwareName, setSoftwareName] = useState('');
    const [person, setPerson] = useState('');
    const [link, setLink] = useState('');
    const [descriptionTA, setDescriptionTA] = useState('');
    const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
    const [phoneNumberValue, setPhoneNumberValue] = useState('');

    const handlePhoneNumberCheckboxChange = (e) => {
        setIsPhoneNumberValid(e.target.checked);
        setPhoneNumberValue('');
      };
    
      const handlePhoneNumberInputChange = (e) => {
        setPhoneNumberValue(e.target.value);
      };
    
      const formatPhoneNumber = (phoneNumber) => {
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);
    
        if (match) {
          return `+${match[1]} (${match[2]}) ${match[3]} ${match[4]} ${match[5]}`;
        }
        return phoneNumber;
      };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (!description) {
            setIsEmpty(true);
            return;
        }
        setIsEmpty(false);
        handleSubmit(e, filesArray);
    };

    const updateDescription = () => {
        setDescription(
            `Название ПО: ${softwareName}\nКому установить: ${person}\nСсылка на дистрибутив: ${link}\n${descriptionTA}\n${phoneNumberValue ? `Контактный номер телефона: ${phoneNumberValue}` : ''}`
        );
    }

    const navigate = useNavigate();

    useEffect(() => {
        updateDescription();
    }, [descriptionTA, person, link, softwareName, phoneNumberValue]);

    return (
        <>
            <img src={back} className="incident-popup__button-back" onClick={handlePrevStep}></img>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
                className="incident-popup__text-block">
                <p className="incident-popup__paragraph" style={{marginBottom: '4px'}}>Выбранная услуга:</p>
                <p className="incident-popup__paragraph" style={{margin: '0'}}>
                    <span style={{ fontSize: '16px' }}>
                        {selectedService?.Service} — {selectedComponent?.ServiceComponent}
                    </span>
                </p>
                {selectedComponent.ServiceDescription ? <p className="incident-popup__subtitle">{selectedComponent?.ServiceDescription}</p> : null}
            </motion.div>

            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
                className="incident-popup__form">
                <form className="incident-popup__form" onSubmit={handleFormSubmit} style={{marginTop: '35px'}}>
                    <div style={{ display: 'flex', flexDirection: 'row', width: 'calc(80% + 20px)', gap: '20px', marginBottom: '30px' }}>
                        <div style={{ width: '50%', display: "flex", alignItems: 'center', flexDirection: 'column', gap: '5px', justifyContent: 'flex-end' }}>
                            <a href={guide} style={{ fontWeight: 'normal', color: '#000' }} target='_blank' rel='noreferrer'>Инструкция по установке ПО из ЦПО</a>
                            <p style={{ fontWeight: 'normal', textAlign: 'left', margin: '0px' }}>Прежде, чем создавать обращение убедитесь, что програмнное обеспечение, которое Вы хотите установить отсутсвует в ЦПО.</p>
                            <p style={{ fontWeight: 'normal', textAlign: 'left', margin: '0px' }}>Всё необходимое ПО уже доступно для установки в Центре программного обеспечения (ЦПО) на Вашем компьютере.</p>
                            <button className="incident-popup__button-cpo" type="button" style={{ height: '40px' }} onClick={() => { window.open('softwarecenter:') }}>
                                <img alt="" src={cpo} />
                                Открыть ЦПО
                            </button>
                        </div>
                        <div style={{ width: '50%' }}>
                            <input style={{ margin: '0px 20px 25px 0', width: '15px', height: '15px' }} type="checkbox" id="cpotypein" checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)} required></input>
                            <label for='cpotypein'>Я убедился что нужного ПО нет в ЦПО</label>
                            <input onChange={(e) => setSoftwareName(e.target.value)} style={{ margin: '0 0 15px 0', width: 'calc(100% - 20px)' }} placeholder="Название ПО" disabled={isChecked ? false : true} required></input>
                            <input onChange={(e) => setPerson(e.target.value)} style={{ margin: '0 0 15px 0', width: 'calc(100% - 20px)' }} placeholder="Кому установить" disabled={isChecked ? false : true} required></input>
                            <input onChange={(e) => setLink(e.target.value)} style={{ margin: '0', width: 'calc(100% - 20px)' }} placeholder="Ссылка на дистрибутив" disabled={isChecked ? false : true} required></input>
                        </div>
                    </div>
                    <textarea
                        className="incident-popup__textarea"
                        id="description"
                        placeholder="Обоснуйте необходимость установки программного обеспечения... "
                        onChange={(e) => setDescriptionTA(e.target.value)}
                        required
                        style={{ height: '75px', paddingBottom: '0px', maxHeight: '200px' }}
                        disabled={isChecked ? false : true}
                    ></textarea>
                    <div className="incident-popup__phone-div">
                        <label className="incident-popup__phone__label">
                            Изменить
                            <input className="incident-popup__phone__checkbox" type="checkbox" onChange={handlePhoneNumberCheckboxChange} checked={isPhoneNumberValid}></input>
                        </label>
                        <InputMask className="incident-popup__phone__input"
                            key='input-phone-mask'
                            mask='+7 (999) 999-99-99'
                            maskChar=''
                            guide={false}
                            type="tel"
                            value={phoneNumberValue}
                            placeholder={!isPhoneNumberValid ? formatPhoneNumber(localStorage.getItem('phone_number')) : '+7'}
                            onChange={(e) => handlePhoneNumberInputChange(e)}
                            disabled={!isPhoneNumberValid}
                        />
                        <p>Ваш контактный номер</p>
                    </div>
                    {isEmpty && <p style={{ color: "red" }}>Поле не может быть пустым</p>}
                    <div className="incident-popup__button-div">
                        <FileInput setFilesArray={setFileBase64List} dropzoneDisabled={!isChecked} />
                        <button className="incident-popup__button" type="submit" 
                        disabled={!person || !softwareName || !link || !isChecked || (isPhoneNumberValid && phoneNumberValue.length !== 18) || (!isPhoneNumberValid && phoneNumberValue.length > 0) || isLoading} >
                            {isLoading ? 'Отправка...' : 'Отправить'}
                        </button>
                    </div>
                </form>
            </motion.div>
        </>
    );
}
