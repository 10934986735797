import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import back from '../../utils/images/back.svg';
import FileInput from "../FileInput/FileInput";
import { useNavigate } from "react-router-dom";
import IOSGuide from '../../utils/Настройка_почты_IOS.pdf';
import AndroidGuide from '../../utils/Настройка_почты_Android.pdf';
import InputMask from "react-input-mask";
export default function MailSetup({
    selectedService,
    selectedComponent,
    handlePrevStep,
    setDescription,
    handleSubmit,
    isLoading,
    description
}) {
    const [isEmpty, setIsEmpty] = useState(false);
    const [filesArray, setFileBase64List] = useState([]);
    const [isChecked, setIsChecked] = useState(false)
    const [descriptionTA, setDescriptionTA] = useState('');
    const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
    const [phoneNumberValue, setPhoneNumberValue] = useState('');

    const handlePhoneNumberCheckboxChange = (e) => {
        setIsPhoneNumberValid(e.target.checked);
        setPhoneNumberValue('');
    };

    const handlePhoneNumberInputChange = (e) => {
        setPhoneNumberValue(e.target.value);
    };

    const formatPhoneNumber = (phoneNumber) => {
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);

        if (match) {
            return `+${match[1]} (${match[2]}) ${match[3]} ${match[4]} ${match[5]}`;
        }
        return phoneNumber;
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (!description) {
            setIsEmpty(true);
            return;
        }
        setIsEmpty(false);
        handleSubmit(e, filesArray);
    };

    const updateDescription = () => {
        setDescription(
            `${descriptionTA}\n${phoneNumberValue ? `Контактный номер телефона: ${phoneNumberValue}` : ''}`
        );
    }

    const navigate = useNavigate();

    useEffect(() => {
        updateDescription();
    }, [descriptionTA, phoneNumberValue]);

    return (
        <>
            <img src={back} className="incident-popup__button-back" onClick={handlePrevStep}></img>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
                className="incident-popup__text-block">
                <p className="incident-popup__paragraph" style={{ marginBottom: '4px' }}>Выбранная услуга:</p>
                <p className="incident-popup__paragraph" style={{ margin: '0' }}>
                    <span style={{ fontSize: '16px' }}>
                        {selectedService?.Service} — {selectedComponent?.ServiceComponent}
                    </span>
                </p>
                {selectedComponent.ServiceDescription ? <p className="incident-popup__subtitle">{selectedComponent?.ServiceDescription}</p> : null}
            </motion.div>

            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
                className="incident-popup__form">
                <form className="incident-popup__form" onSubmit={handleFormSubmit} style={{ marginTop: '30px' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', width: 'calc(80% + 20px)', gap: '20px' }}>
                        <div style={{ width: '100%', textAlign: 'center' }}>
                            <p style={{ margin: '0px' }}>Для настройки почты на своём мобильном устройстве воспользуйтесь соответствующей инструкцией:</p>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', marginTop: '20px' }}>
                                <a
                                    style={{ color: '#000', fontWeight: 'bold' }}
                                    href={IOSGuide}
                                    target='_blank'
                                    rel='noreferrer'>Для IOS</a>
                                <a
                                    style={{ color: '#000', fontWeight: 'bold' }}
                                    href={AndroidGuide}
                                    target='_blank'
                                    rel='noreferrer'>Для Android</a>
                            </div>
                            <input
                                style={{ margin: '20px 10px 20px', width: '15px', height: '15px', textAlign: 'center' }}
                                type="checkbox"
                                id="cpotypein"
                                checked={isChecked}
                                onChange={(e) => setIsChecked(e.target.checked)}
                                required>
                            </input>
                            <label for='cpotypein'>Инструкция не помогает</label>
                        </div>
                    </div>
                    <textarea
                        className="incident-popup__textarea"
                        id="description"
                        placeholder="Подробно опишите, с чем Вам нужна помощь..."
                        onChange={(e) => setDescriptionTA(e.target.value)}
                        required
                        style={{ height: '95px', paddingBottom: '0px', maxHeight: '200px', margin: '0px' }}
                        disabled={isChecked ? false : true}
                    ></textarea>
                    <div className="incident-popup__phone-div">
                        <label className="incident-popup__phone__label">
                            Изменить
                            <input className="incident-popup__phone__checkbox" type="checkbox" onChange={handlePhoneNumberCheckboxChange} checked={isPhoneNumberValid}></input>
                        </label>
                        <InputMask className="incident-popup__phone__input"
                            key='input-phone-mask'
                            mask='+7 (999) 999-99-99'
                            maskChar=''
                            guide={false}
                            type="tel"
                            value={phoneNumberValue}
                            placeholder={!isPhoneNumberValid ? formatPhoneNumber(localStorage.getItem('phone_number')) : '+7'}
                            onChange={(e) => handlePhoneNumberInputChange(e)}
                            disabled={!isPhoneNumberValid}
                        />
                        <p>Ваш контактный номер</p>
                    </div>
                    {isEmpty && <p style={{ color: "red" }}>Поле не может быть пустым</p>}
                    <div className="incident-popup__button-div">
                        <FileInput setFilesArray={setFileBase64List} dropzoneDisabled={!isChecked} />
                        <button className="incident-popup__button" type="submit" disabled={!isChecked || (isPhoneNumberValid && phoneNumberValue.length !== 18) || (!isPhoneNumberValid && phoneNumberValue.length > 0) || isLoading} >
                            {isLoading ? 'Отправка...' : 'Отправить'}
                        </button>
                    </div>
                </form>
            </motion.div>
        </>
    );
}
