import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import './Rate.css';
import RatePopup from './RatePopup';
import star_filled from '../../utils/images/star_filled.svg';
import star from '../../utils/images/star.svg';
import dropdown from '../../utils/images/dropDown.svg'
export default function Rate({ selectedIncidentUuid, fetchSelectedIncident, fetchIncidents, selectedIncident, setCommentOpened, isCommentOpened }) {
    const [isConfirmPopupOpened, setConfirmPopupOpened] = useState(false);
    const [isRejectPopupOpened, setRejectPopupOpened] = useState(false);

    return (
        <>
            <motion.div className='rate__div'>
                <RatePopup
                    setPopupOpened={setConfirmPopupOpened}
                    isPopupOpened={isConfirmPopupOpened}
                    popupType="confirm"
                    selectedIncidentUuid={selectedIncidentUuid}
                    fetchSelectedIncident={fetchSelectedIncident}
                    fetchIncidents={fetchIncidents}
                />
                <RatePopup
                    setPopupOpened={setRejectPopupOpened}
                    isPopupOpened={isRejectPopupOpened}
                    popupType="reject"
                    selectedIncidentUuid={selectedIncidentUuid}
                    fetchSelectedIncident={fetchSelectedIncident}
                    fetchIncidents={fetchIncidents}
                />
                {selectedIncident.commentaryOnTheAssessment.length > 0 &&
                    <div className='rate__comment-block' onClick={() => setCommentOpened(!isCommentOpened)}>
                        <img style={{ transform: isCommentOpened ? 'rotate(180deg)' : 'rotate(0deg)', transition: '.2s ease-in-out' }} src={dropdown}></img>
                        <p>Комментарий</p>
                    </div>}
                {parseInt(selectedIncident.grade) > 0 ?
                    <>
                        {[...Array(5)].map((_, index) => (
                            <motion.img layout
                                key={index}
                                className='rate__star'
                                src={index <= (selectedIncident.grade - 1) ? star_filled : star}
                            />
                        ))}
                    </>
                    : null}
                {parseInt(selectedIncident.grade) === 0 & selectedIncident.state === 'Выполнено. Требует подтверждения' ?
                    <div className='rate__button__div'>
                        <button className='rate__button' onClick={() => setConfirmPopupOpened(true)}>Подтвердить</button>
                        <button className='rate__button_grey' onClick={() => setRejectPopupOpened(true)}>Отклонить</button>
                    </div>
                    : null
                }
            </motion.div>
        </>
    );
}
