import TextareaAutosize from 'react-textarea-autosize';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import star_filled from '../../utils/images/star_filled.svg';
import star from '../../utils/images/star.svg';
import api from '../../utils/Api';
import { useLocation, useNavigate } from 'react-router-dom';

export default function RatePopup({ isPopupOpened, popupType, setPopupOpened, selectedIncidentUuid, fetchSelectedIncident, fetchIncidents }) {
    const [hoveredStarIndex, setHoveredStarIndex] = useState(null);
    const [selectedRate, setRate] = useState(null);
    const [comment, setComment] = useState('');
    const [isSuccess, setSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const popupRef = useRef(null);
    const navigate = useNavigate();
    const handleSubmit = async () => {
        try {
            setIsLoading(true);
            const token = localStorage.getItem('jwt');
            const res = await api.confirmIncident(token, selectedIncidentUuid, selectedRate, comment, popupType === 'confirm');
            setSuccess(true);
        } catch (error) {
            console.log(error);
            setSuccess(false);
        } finally {
            setTimeout(() => {
                setPopupOpened(false);
                setSuccess(false);
                navigate(0);
                setIsLoading(false);
            }, 3000)
        }
    }




    const handleStarHover = (index) => {
        setHoveredStarIndex(index);
    };

    const handleStarLeave = () => {
        setHoveredStarIndex(null);
    };

    const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
            setPopupOpened(false);
        }
    };

    useEffect(() => {
        setHoveredStarIndex(null);
        setRate(null);
    }, [isPopupOpened])

    useEffect(() => {
        if (isPopupOpened) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isPopupOpened]);


    if (!isPopupOpened) return null;

    if (isSuccess) return (
        <>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="rate__popup"
                ref={popupRef}
            >
                {popupType === 'confirm' ?
                    <>
                        <p className='rate__p' style={{ fontWeight: 'bold' }}>Спасибо!</p>
                        <p className='rate__p' style={{ textAlign: 'center' }}>Выполнение подтверждено! Ваш отзыв получен</p>
                    </>
                    :

                    <>
                        <p className='rate__p' style={{ fontWeight: 'bold' }}>Обращение не выполнено</p>
                        <p className='rate__p' style={{ textAlign: 'center' }}>Разберёмся в чём проблема, как можно скорее.</p>
                    </>
                }
            </motion.div>
        </>

    )

    const getContent = () => {
        switch (popupType) {
            case 'confirm':
                return (
                    <>
                        <p className='rate__p' style={{ fontWeight: 'bold' }}>Подтвердите выполнение</p>
                        <p className='rate__p' style={{ textAlign: 'left' }}>Оцените качество выполненной работы и, по желанию, оставьте комментарий.</p>
                        <div className='rate__stars__div'>
                            {[...Array(5)].map((_, index) => (
                                <motion.img layout
                                    key={index}
                                    className='rate__star'
                                    src={index <= (hoveredStarIndex !== null ? hoveredStarIndex : selectedRate - 1) ? star_filled : star}
                                    onMouseEnter={() => handleStarHover(index)}
                                    onMouseLeave={handleStarLeave}
                                    onClick={() => {
                                        setRate(index + 1);
                                        setHoveredStarIndex(null);
                                    }}
                                />
                            ))}
                        </div>
                    </>
                );
            case 'reject':
                return (
                    <>
                        <p className='rate__p' style={{ fontWeight: 'bold', textAlign: 'center' }}>Обращение не выполнено</p>
                        <p className='rate__p' style={{ textAlign: 'center' }}>Расскажите нам почему Вы считаете, что Ваше обращение не выполнено.</p>
                    </>
                )
            default:
                return null;
        }
    };

    return (
        <>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="rate__popup"
                ref={popupRef}
                key='animratepop'
            >
                {getContent(popupType)}
                <TextareaAutosize
                    maxRows={4}
                    required={selectedRate  <= 3}
                    className='rate__textarea'
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    placeholder='Оставьте комментарий'></TextareaAutosize>
                <button className='rate__button' onClick={() => handleSubmit()} disabled={isLoading || (!selectedRate && popupType === 'confirm')}>Отправить</button>
            </motion.div>
        </>
    );
}
