import { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import back from '../../utils/images/back.svg';
import Select from 'react-select';
import FileInput from "../FileInput/FileInput";
import InputMask from "react-input-mask";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { ru } from 'date-fns/locale/ru';

import "react-datepicker/dist/react-datepicker.css";

export default function Roaming({
    selectedService,
    selectedComponent,
    handlePrevStep,
    setDescription,
    handleSubmit,
    isLoading,
    description,
    setMobilePhone,
    mobilePhone
}) {
    registerLocale('ru', ru);
    setDefaultLocale('ru');

    const [isEmpty, setIsEmpty] = useState(false);
    const [filesArray, setFileBase64List] = useState([]);
    const [descriptionTA, setDescriptionTA] = useState('');
    const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
    const [phoneNumberValue, setPhoneNumberValue] = useState('');

    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedServiceRoaming, setSelectedServiceRoaming] = useState('');
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    function formatDate(date) {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
    }

    function formatDateRange(dateRange) {
        if (dateRange[0] && dateRange[1]) {
            return `${formatDate(dateRange[0])} — ${formatDate(dateRange[1])}`;
        }
        return '';
    }

    const handlePhoneNumberCheckboxChange = (e) => {
        setIsPhoneNumberValid(e.target.checked);
        setPhoneNumberValue('');
    };

    const handlePhoneNumberInputChange = (e) => {
        setPhoneNumberValue(e.target.value);
        setMobilePhone(e.target.value);
    };

    const formatPhoneNumber = (phoneNumber) => {
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);

        if (match) {
            return `+${match[1]} (${match[2]}) ${match[3]} ${match[4]} ${match[5]}`;
        }
        return phoneNumber;
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        updateDescription();
        if (!descriptionTA.trim()) {
            setIsEmpty(true);
            return;
        }
        setIsEmpty(false);
        handleSubmit(e, filesArray);
    };

    const updateDescription = () => {
        const fullDescription =
            `Период: ${formatDateRange(dateRange)}\nСтрана роуминга: ${selectedCountry}\nТип услуги: ${selectedServiceRoaming?.value}\n${descriptionTA}`;
        setDescription(fullDescription);
    };

    useEffect(() => {
        updateDescription();
    }, [selectedServiceRoaming, dateRange, descriptionTA, selectedCountry]);


    const services = [
        {
            'value': 'Мобильная связь',
            'label': 'Мобильная связь'
        },
        {
            'value': 'Интернет',
            'label': 'Интернет'
        },
        {
            'value': 'Мобильная связь и интернет',
            'label': 'Мобильная связь и интернет'
        }
    ];

    const selectStyles = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            width: 'auto',
            fontFamily: 'Circe',
            height: '42px',
            zIndex: '20',
            borderRadius: '10px',
            border: '1px solid #B3B3B3',
            textAlign: 'left',
            boxShadow: state.isFocused ? 0 : 0,
            '&:hover': {
                border: state.isFocused ? '1px solid #0082C8' : '1px solid #B3B3B3'
            },
            backgroundColor: state.isDisabled ? '#fff' : '#fff',
            opacity: state.isDisabled ? .5 : 1
        }),
        option: (baseStyles) => ({
            ...baseStyles,
            borderRadius: '10px'
        }),
        valueContainer: (baseStyles) => ({
            ...baseStyles,
            padding: '0px',
            fontFamily: 'Circe',
            height: '38px',
            paddingLeft: '10px'
        }),
        container: (baseStyles) => ({
            ...baseStyles,
            fontFamily: 'Circe',
            width: '33%',
            height: '40px'
        }),
        placeholder: (baseStyles) => ({
            ...baseStyles,
            fontSize: '15px',
            textAlign: 'left',
            fontFamily: 'Circe',
            fontWeight: 'normal',
            height: '40px',
            marginBottom: '5px',
            color: '#000',
        }),
        singleValue: (baseStyles) => ({
            ...baseStyles,
            fontSize: '15px',
            fontFamily: 'Circe',
            height: '38px',
            marginBottom: '6px',
            borderRadius: '10px',
            color: '#000',
        }),
        menuPortal: (baseStyles) => ({
            ...baseStyles,
            zIndex: 9999,
            textAlign: 'left'
        }),
        menu: (baseStyles) => ({
            ...baseStyles,
            padding: '0px',
            borderRadius: '10px',
            textAlign: 'left',
            fontFamily: 'Circe',
            fontSize: '15px',
            borderColor: '#B3B3B3',
        }),
        menuList: (baseStyles) => ({
            ...baseStyles,
            padding: '0',
        }),

    };


    return (
        <>
            <img src={back} className="incident-popup__button-back" onClick={handlePrevStep} alt="Назад"></img>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
                className="incident-popup__text-block">
                <p className="incident-popup__paragraph">Выбранная услуга:</p>
                <p className="incident-popup__paragraph" style={{ margin: '0' }}>
                    <span>
                        {selectedService?.Service} — {selectedComponent?.ServiceComponent}
                    </span>
                </p>
                {selectedComponent.ServiceDescription ? <p className="incident-popup__subtitle">{selectedComponent?.ServiceDescription}</p> : null}
            </motion.div>

            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
                className="incident-popup__form">
                <form className="incident-popup__form" onSubmit={handleFormSubmit} style={{ marginTop: '25px' }}>
                    <p style={{ margin: '0px 0px 15px 0px' }}>Заполните данные:</p>
                    <div style={{ display: 'flex', flexDirection: 'row', width: 'calc(80% + 20px)' }}>
                        <DatePicker
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(update) => {
                                setDateRange(update);
                            }}
                            dateFormat="dd.MM.yyyy"
                            isClearable={true}
                            locale='ru'
                            placeholderText="Дата начала - Дата окончания"
                            required
                        />
                        <input
                            style={{ width: '30%', margin: '0 15px 0 15px' }}
                            placeholder="Страна роуминга"
                            value={selectedCountry}
                            onChange={(e) => setSelectedCountry(e.target.value)}
                            required
                        />
                        <Select
                            options={services}
                            placeholder="Выберите услугу"
                            styles={selectStyles}
                            value={selectedServiceRoaming}
                            onChange={setSelectedServiceRoaming}
                            isSearchable={false}
                            required
                            isDisabled={false}
                        />
                    </div>
                    <motion.p
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        className="incident-popup__paragraph" style={{ marginTop: '70px', marginBottom: '15px' }}>Описание обращения:</motion.p>
                    <textarea
                        className="incident-popup__textarea"
                        id="description"
                        placeholder="Подробно опишите, с чем Вам нужна помощь..."
                        value={descriptionTA}
                        onChange={(e) => setDescriptionTA(e.target.value)}
                        required
                        style={{ paddingBottom: '50px' }}
                    ></textarea>
                    <div className="incident-popup__phone-div">
                        <label className="incident-popup__phone__label">
                            Изменить
                            <input className="incident-popup__phone__checkbox" type="checkbox" onChange={handlePhoneNumberCheckboxChange} checked={isPhoneNumberValid}></input>
                        </label>
                        <InputMask className="incident-popup__phone__input"
                            key='input-phone-mask'
                            mask='+7 (999) 999-99-99'
                            maskChar=''
                            guide={false}
                            type="tel"
                            value={phoneNumberValue}
                            placeholder={!isPhoneNumberValid ? formatPhoneNumber(localStorage.getItem('phone_number')) : '+7'}
                            onChange={(e) => handlePhoneNumberInputChange(e)}
                            disabled={!isPhoneNumberValid}
                        />
                        <p>Ваш контактный номер</p>
                    </div>
                    {isEmpty && <p style={{ color: "red" }}>Поле не может быть пустым</p>}
                    <div className="incident-popup__button-div">
                        <FileInput setFilesArray={setFileBase64List} />
                        <button
                            className="incident-popup__button" type="submit"
                            disabled={!startDate || !endDate || !selectedCountry || !selectedServiceRoaming || isLoading || (isPhoneNumberValid && phoneNumberValue.length !== 18) || (!isPhoneNumberValid && phoneNumberValue.length > 0)}>
                            {isLoading ? 'Отправка...' : 'Отправить'}
                        </button>
                    </div>
                </form>
            </motion.div>
        </>
    );
}
