import React, { useEffect, useState } from 'react';
import { motion } from "framer-motion";
import delete_img from '../../utils/images/delete.svg';
import back from '../../utils/images/back.svg';
import plus from '../../utils/images/Plus-button.svg';
import FileInput from "../FileInput/FileInput";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { TextareaAutosize } from '@mui/material';

export default function FolderTypeInStep({
    selectedService,
    selectedComponent,
    handlePrevStep,
    setDescription,
    handleSubmit,
    isLoading,
}) {
    const selectStyles = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            fontFamily: 'Circe',
            height: '42px',
            borderRadius: '10px',
            border: '1px solid #B3B3B3',
            boxShadow: state.isFocused ? 0 : 0,
            '&:hover': {
                border: state.isFocused ? '1px solid #0082C8' : '1px solid #B3B3B3'
            }
        }),
        valueContainer: (baseStyles) => ({
            ...baseStyles,
            padding: '0px',
            fontFamily: 'Circe',
            height: '38px',
            paddingLeft: '10px'
        }),
        container: (baseStyles) => ({
            ...baseStyles,
            fontFamily: 'Circe',
            width: '100%',
            height: '40px'
        }),
        menu: (baseStyles) => ({
            ...baseStyles,
            fontSize: '15px',
            fontFamily: 'Circe',
            borderRadius: '10px',
            textAlign: 'left'
        }),
        placeholder: (baseStyles) => ({
            ...baseStyles,
            fontSize: '15px',
            textAlign: 'left',
            fontFamily: 'Circe',
            fontWeight: 'normal',
            height: '40px',
            marginBottom: '5px'
        }),
        singleValue: (baseStyles) => ({
            ...baseStyles,
            fontSize: '15px',
            fontFamily: 'Circe',
            height: '38px',
            marginBottom: '6px',
            borderRadius: '10px'
        }),
        menuList: (baseStyles) => ({
            ...baseStyles,
            padding: '0',
        }),
    };

    const [descriptionTA, setDescriptionTA] = useState('');
    const [isEmpty, setIsEmpty] = useState(false);
    const [filesArray, setFileBase64List] = useState([]);
    const [rowsArray, setRowsArray] = useState([{ path: '', accessType: '', personList: '' }]);
    const [isGroupFolders, setIsGroupFolders] = useState(false);

    const typeSelect = [
        { value: "Только чтение", label: "Только чтение" },
        { value: "Чтение и запись", label: "Чтение и запись" }
    ];

    const handleFormSubmit = (e) => {
        e.preventDefault();
        handleSubmit(e, filesArray);
    };

    const updateDescription = () => {
        const fullDescription = [
            descriptionTA,
            'Необходим доступ к следующим папкам:',
            ...rowsArray.map((item) => `${item.personList.length > 0 ? `Нужен для: ${item.personList}\n` : ''}Путь: ${item.path}\nТип доступа: ${item.accessType}\n`)
        ].join('\n\n');
        setDescription(fullDescription);
    };

    useEffect(() => {
        updateDescription();
    }, [descriptionTA, rowsArray]);


    useEffect(() => {
        setRowsArray([{ path: '', accessType: '', personList: '' }]);
    }, [isGroupFolders]);

    const handleDeleteRow = (index) => {
        const updatedRows = [...rowsArray];
        updatedRows.splice(index, 1);
        setRowsArray(updatedRows);
    };

    const handleGroupFoldersToggle = () => {
        setIsGroupFolders(!isGroupFolders);
    };

    const addRow = () => {
        const newRow = { path: '', accessType: '', personList: '' };
        setRowsArray(prevRows => [...prevRows, newRow]);
    };

    const handleRowChange = (index, field, value) => {
        const updatedRows = [...rowsArray];
        updatedRows[index][field] = value;
        setRowsArray(updatedRows);
    };

    return (
        <>
            <img src={back} className="incident-popup__button-back" onClick={handlePrevStep} alt="Back"></img>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
                className="incident-popup__text-block">
                <p className="incident-popup__paragraph">Выбранная услуга:</p>
                <p className="incident-popup__paragraph" style={{ margin: '0' }}>
                    <span>
                        {selectedService?.Service} — {selectedComponent?.ServiceComponent}
                    </span>
                </p>
                {selectedComponent.ServiceDescription ? <p className="incident-popup__subtitle">{selectedComponent?.ServiceDescription}</p> : null}
            </motion.div>

            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
                className="incident-popup__form">
                <div className='incident-popup__folders-switch'>
                    <p style={{ margin: '0', fontWeight: 'normal' }}>Доступ для группы лиц</p>
                    <label className="switch">
                        <input type="checkbox" checked={isGroupFolders} onChange={handleGroupFoldersToggle} />
                        <span className="slider round"></span>
                    </label>
                </div>
                <form className="incident-popup__form" onSubmit={handleFormSubmit}>

                    {isGroupFolders ? (
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.2 }}
                            className='incident-popup__folders-div'>
                            <div className='incident-popup__folders-header'>
                                <p className='' style={{ width: '300px' }}>Полный путь к папке</p>
                                <p className='' style={{ width: '135px' }}>Тип доступа</p>
                                <p className='' style={{ width: '300px' }}>Кому нужен</p>
                            </div>
                            <div className='incident-popup__folders-body'>
                                {rowsArray.map((row, index) => (
                                    <div key={index} className='incident-popup__folders-row-4'>
                                        <button src={delete_img}
                                            className='incident-popup__folders-delete'
                                            alt="Delete"
                                            style={{ opacity: index ? '1' : '0' }}
                                            onClick={() => handleDeleteRow(index)}
                                            disabled={index ? false : true}
                                        >
                                        </button>
                                        <input
                                            type="text"
                                            placeholder='Введите путь к папке'
                                            style={{ margin: 0, width: 'calc(100% - 20px)', height: '20px' }}
                                            className='incident-popup__folders-input'
                                            name="path"
                                            value={row.path}
                                            onChange={(e) => handleRowChange(index, 'path', e.target.value)}
                                        />
                                        <Select
                                            isSearchable={false}
                                            options={typeSelect}
                                            placeholder="Тип доступа"
                                            name="accessType"
                                            value={typeSelect.find(option => option.value === row.accessType)}
                                            onChange={(selectedOption) => handleRowChange(index, 'accessType', selectedOption.value)}
                                            styles={selectStyles}
                                            required
                                        />
                                        <TextareaAutosize
                                            className='incident-popup__folders-input'
                                            placeholder="Введите ФИО"
                                            value={row.personList}
                                            onChange={(e) => handleRowChange(index, 'personList', e.target.value)}
                                            style={{ fontFamily: 'inherit', resize: 'none', minHeight: '30px', padding: '9px 10px 0px 10px', width: 'calc(100% - 20px)' }}
                                            maxRows={2}
                                            required
                                        />
                                    </div>
                                ))}
                                {rowsArray.length < 6 && <img className='incident-popup__folders-plus' src={plus} alt="Add Row" onClick={addRow}></img>}
                            </div>
                        </motion.div>
                    ) : (
                        <>
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.2 }}
                                className="incident-popup__folders-div">
                                <div className='incident-popup__folders-header'>
                                    <p className='' style={{ width: '60%' }}>Полный путь к папке</p>
                                    <p className='' style={{ width: '40%' }}>Тип доступа</p>
                                </div>
                                <div className='incident-popup__folders-body'>
                                    {rowsArray.map((row, index) => (
                                        <div key={index} className='incident-popup__folders-row'>
                                            <button
                                                className='incident-popup__folders-delete'
                                                alt="Delete"
                                                style={{ opacity: index ? '1' : '0' }}
                                                onClick={() => handleDeleteRow(index)}
                                                disabled={index ? false : true}
                                            >
                                            </button>
                                            <input
                                                type="text"
                                                placeholder='Введите путь к папке'
                                                style={{ margin: 0, width: 'calc(100% - 20px)', height: '20px' }}
                                                className='incident-popup__folders-input'
                                                name="path"
                                                value={row.path}
                                                onChange={(e) => handleRowChange(index, 'path', e.target.value)}
                                                required
                                            />
                                            <Select
                                                isSearchable={false}
                                                options={typeSelect}
                                                placeholder="Тип доступа"
                                                name="accessType"
                                                value={typeSelect.find(option => option.value === row.accessType)}
                                                onChange={(selectedOption) => handleRowChange(index, 'accessType', selectedOption.value)}
                                                styles={selectStyles}
                                                required
                                            />
                                        </div>
                                    ))}
                                </div>
                                {rowsArray.length < 6 && <img className='incident-popup__folders-plus' src={plus} alt="Add Row" onClick={addRow}></img>}
                            </motion.div>
                        </>
                    )}
                    <textarea
                        style={{ paddingBottom: '0', height: '41px' }}
                        className="incident-popup__textarea"
                        id="description"
                        placeholder="Если необходимо, дополните уточняющей информацией..."
                        onChange={(e) => setDescriptionTA(e.target.value)}
                    ></textarea>
                    <div className="incident-popup__button-div">
                        <FileInput setFilesArray={setFileBase64List} />
                        <button className="incident-popup__button" type="submit" disabled={isLoading}>
                            {isLoading ? 'Отправка...' : 'Отправить'}
                        </button>
                    </div>
                </form>
            </motion.div>
        </>
    );
}
