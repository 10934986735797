import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import back from '../../utils/images/back.svg';
import FileInput from "../FileInput/FileInput";
import { useNavigate } from "react-router-dom";
import cpo from '../../utils/images/cpo.svg';
import guide from '../../utils/Установка приложений из ЦПО.pdf';
import InputMask from "react-input-mask";
export default function BankTrust({
    selectedService,
    selectedComponent,
    handlePrevStep,
    setDescription,
    handleSubmit,
    isLoading,
    description
}) {
    const [isEmpty, setIsEmpty] = useState(false);
    const [filesArray, setFileBase64List] = useState([]);
    const [isChecked, setIsChecked] = useState(false)
    const [softwareName, setSoftwareName] = useState('');
    const [person, setPerson] = useState('');
    const [link, setLink] = useState('');
    const [descriptionTA, setDescriptionTA] = useState('');
    const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
    const [phoneNumberValue, setPhoneNumberValue] = useState('');

    const handlePhoneNumberCheckboxChange = (e) => {
        setIsPhoneNumberValid(e.target.checked);
        setPhoneNumberValue('');
    };

    const handlePhoneNumberInputChange = (e) => {
        setPhoneNumberValue(e.target.value);
    };

    const formatPhoneNumber = (phoneNumber) => {
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);

        if (match) {
            return `+${match[1]} (${match[2]}) ${match[3]} ${match[4]} ${match[5]}`;
        }
        return phoneNumber;
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (!description) {
            setIsEmpty(true);
            return;
        }
        setIsEmpty(false);
        handleSubmit(e, filesArray);
    };

    const updateDescription = () => {
        setDescription(
            `Название ПО: ${softwareName}\nКому установить: ${person}\nСсылка на дистрибутив: ${link}\n${descriptionTA}\n${phoneNumberValue ? `Контактный номер телефона: ${phoneNumberValue}` : ''}`
        );
    }

    const navigate = useNavigate();

    useEffect(() => {
        updateDescription();
    }, [descriptionTA, person, link, softwareName, phoneNumberValue]);

    return (
        <>
            <img src={back} className="incident-popup__button-back" onClick={handlePrevStep}></img>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
                className="incident-popup__text-block">
                <p className="incident-popup__paragraph" style={{ marginBottom: '4px' }}>Выбранная услуга:</p>
                <p className="incident-popup__paragraph" style={{ margin: '0' }}>
                    <span style={{ fontSize: '16px' }}>
                        {selectedService?.Service} — {selectedComponent?.ServiceComponent}
                    </span>
                </p>
                {selectedComponent.ServiceDescription ? <p className="incident-popup__subtitle">{selectedComponent?.ServiceDescription}</p> : null}
            </motion.div>

            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
                className="incident-popup__form">
                <form className="incident-popup__form" onSubmit={handleFormSubmit} style={{ marginTop: '30px' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', width: 'calc(80% + 20px)', gap: '20px' }}>
                        <div style={{ width: '100%', textAlign: 'left' }}>
                            <p style={{ marginTop: '0px' }}>Прежде, чем направлять заявку, перейдите в регламент <a
                                href="http://wiki.pridex.local/pages/viewpage.action?pageId=78053403" target="_blank"
                                style={{ fontWeight: 'bold', textDecoration: 'underline', color: '#000' }}>Оформление доступа к ЭДО (СБИС, 1С) и выпуск ЭЦП </a>
                                Заполните форму согласования выпуска ЭЦП и согласуйте выпуск ЭЦП и / или доверенности (МЧД).</p>
                            <p style={{ margin: '10px 0 10px 0' }}><span style={{ fontWeight: 'bold' }}>Обязательно</span> к заявке приложить 3 файла:</p>
                            <ul style={{ margin: '0', paddingLeft: '20px' }}>
                                <li>Форму согласования доступа</li>
                                <li>Согласование непосредственного руководителя</li>
                                <li>Согласование вышестоящего руководства</li>
                            </ul>
                            <input style={{ margin: '30px 10px 30px 15%', width: '15px', height: '15px', textAlign: 'center' }} type="checkbox" id="cpotypein" checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)} required></input>
                            <label for='cpotypein'>Я подтверждаю, что доступ в СБИС согласован и к заявке приложены 3 файла</label>
                        </div>
                    </div>
                    <textarea
                        className="incident-popup__textarea"
                        id="description"
                        placeholder="Подробно опишите, с чем Вам нужна помощь..."
                        onChange={(e) => setDescriptionTA(e.target.value)}
                        required
                        style={{ height: '45px', paddingBottom: '0px', maxHeight: '200px' }}
                        disabled={isChecked ? false : true}
                    ></textarea>
                    <div className="incident-popup__phone-div">
                        <label className="incident-popup__phone__label">
                            Изменить
                            <input className="incident-popup__phone__checkbox" type="checkbox" onChange={handlePhoneNumberCheckboxChange} checked={isPhoneNumberValid}></input>
                        </label>
                        <InputMask className="incident-popup__phone__input"
                            key='input-phone-mask'
                            mask='+7 (999) 999-99-99'
                            maskChar=''
                            guide={false}
                            type="tel"
                            value={phoneNumberValue}
                            placeholder={!isPhoneNumberValid ? formatPhoneNumber(localStorage.getItem('phone_number')) : '+7'}
                            onChange={(e) => handlePhoneNumberInputChange(e)}
                            disabled={!isPhoneNumberValid}
                        />
                        <p>Ваш контактный номер</p>
                    </div>
                    {isEmpty && <p style={{ color: "red" }}>Поле не может быть пустым</p>}
                    <div className="incident-popup__button-div">
                        <FileInput setFilesArray={setFileBase64List} />
                        <button className="incident-popup__button" type="submit" disabled={!isChecked || (isPhoneNumberValid && phoneNumberValue.length !== 18) || (!isPhoneNumberValid && phoneNumberValue.length > 0) || isLoading} >
                            {isLoading ? 'Отправка...' : 'Отправить'}
                        </button>
                    </div>
                </form>
            </motion.div>
        </>
    );
}
