import { useEffect, useRef, useState } from "react";
import { AnimatePresence, motion, useReducedMotion } from "framer-motion";
import back from '../../utils/images/back.svg';
import FileInput from "../FileInput/FileInput";
import InputMask from 'react-input-mask';

export default function TypeInStep({
  selectedService,
  selectedComponent,
  handlePrevStep,
  setDescription,
  handleSubmit,
  isLoading,
  description,
  setMobilePhone,
  mobilePhone
}) {
  const [isEmpty, setIsEmpty] = useState(false);
  const [filesArray, setFileBase64List] = useState([]);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
  const [phoneNumberValue, setPhoneNumberValue] = useState('');

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (!description) {
      setIsEmpty(true);
      return;
    }
    setIsEmpty(false);
    handleSubmit(e, filesArray);
  };

  const handlePhoneNumberCheckboxChange = (e) => {
    setIsPhoneNumberValid(e.target.checked);
    setPhoneNumberValue('');
  };

  const handlePhoneNumberInputChange = (e) => {
    setPhoneNumberValue(e.target.value);
    setMobilePhone(e.target.value);
  };

  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);

    if (match) {
      return `+${match[1]} (${match[2]}) ${match[3]} ${match[4]} ${match[5]}`;
    }
    return phoneNumber;
  };

  return (
    <>
      <img src={back} className="incident-popup__button-back" onClick={handlePrevStep} alt="Back"></img>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
        className="incident-popup__text-block">
        <p className="incident-popup__paragraph">Выбранная услуга:</p>
        <p className="incident-popup__paragraph" style={{margin: '0'}}>
          <span>
            {selectedService?.Service} — {selectedComponent?.ServiceComponent}
          </span>
        </p>
        {selectedComponent.ServiceDescription ? <p className="incident-popup__subtitle">{selectedComponent?.ServiceDescription}</p> : null}
      </motion.div>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
        className="incident-popup__form">
        <form className="incident-popup__form" onSubmit={handleFormSubmit}>
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="incident-popup__paragraph" style={{ marginTop: '70px', marginBottom: '15px' }}>Описание:</motion.p>
          <textarea
            className="incident-popup__textarea"
            id="description"
            placeholder="Подробно опишите, с чем Вам нужна помощь..."
            onChange={(e) => setDescription(e.target.value)}
            required
          ></textarea>
          <div className="incident-popup__phone-div">
            <label className="incident-popup__phone__label">
              Изменить
              <input className="incident-popup__phone__checkbox" type="checkbox" onChange={handlePhoneNumberCheckboxChange} checked={isPhoneNumberValid}></input>
            </label>
            <InputMask className="incident-popup__phone__input"
              key='input-phone-mask'
              mask='+7 (999) 999-99-99'
              maskChar=''
              guide={false}
              type="tel"
              value={phoneNumberValue}
              placeholder={!isPhoneNumberValid ? formatPhoneNumber(localStorage.getItem('phone_number')) : '+7'}
              onChange={(e) => handlePhoneNumberInputChange(e)}
              disabled={!isPhoneNumberValid}
            />
            <p>Ваш контактный номер</p>
          </div>
          {isEmpty && <p style={{ color: "red" }}>Поле не может быть пустым</p>}
          <div className="incident-popup__button-div">
            <FileInput setFilesArray={setFileBase64List} />
            <button className="incident-popup__button" type="submit" disabled={isLoading || (isPhoneNumberValid && phoneNumberValue.length !== 18) || (!isPhoneNumberValid && phoneNumberValue.length > 0)}>
              {isLoading ? 'Отправка...' : 'Отправить'}
            </button>
          </div>
        </form>
      </motion.div>
    </>
  );
}
